/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Image, Text, PriceWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Zdravotní pojišťovny a ceník"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pt--80" name={"abl9bc5j6vc"} style={{"paddingBottom":57,"backgroundColor":"rgba(196, 232, 188, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--1 fs--30" content={"ZDRAVOTNÍ POJIŠŤOVNY A CENÍK"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center fs--18" content={"MÁME SMLOUVU SE VŠEMI ZDRAVOTNÍMI POJIŠŤOVNAMI"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"840gwnwy7qx"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3564/56f16961b17145d181f0c535ee4fa87f_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/3564/56f16961b17145d181f0c535ee4fa87f_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">VŠŠEOBECNÁ ZDRAVOTNÍ POJIŠŤOVNA</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">111</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3564/c8b94faaafca43d79e49afd0c670aa9e_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/3564/c8b94faaafca43d79e49afd0c670aa9e_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">VOJENSKÁ ZDRAVOTNÍ POJIŠŤOVNA</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">201</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3564/e95902b352a4438597fd60142d82bdb9_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/3564/e95902b352a4438597fd60142d82bdb9_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">ZDRAVOTNÍ POJIŠŤOVNA MINISTERSTVA VNITRA ČR</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">211</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3564/98579afa66c74f6db3c4052e5a4687fb_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/3564/98579afa66c74f6db3c4052e5a4687fb_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">ČESKÁ PRŮMYSLOVÁ ZDRAVOTNÍ POJIŠŤOVNA</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">205</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3564/6c60b649ea284e1c9e49e11013bf8037_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/3564/6c60b649ea284e1c9e49e11013bf8037_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">ZAMĚSTNANECKÁ POJIŠŤOVNA ŠKODA</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">209</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3564/448e88d568b840318f58123895becb69_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/3564/448e88d568b840318f58123895becb69_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(0, 0, 0);\">REVÍRNÍ BRATRSKÁ POKLADNA</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">213</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/3564/99a87f1ee362483f8142825ee965d600_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={"https://cdn.swbpg.com/t/3564/99a87f1ee362483f8142825ee965d600_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box ff--1 swpf--uppercase" content={"<span style=\"color: var(--black);\">Oborová zdravotní pojišťovna zaměstnanců bank, pojišťoven a stavebnictví</span><br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">207</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>



        <Column className="pb--30 pt--30" name={"zdravotni-pojistovny"} style={{"backgroundColor":"rgba(214,239,208,1)"}} layout={"l21"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--1 fs--24" content={"CENÍK VÝKONŮ NEHRAZENÝCH ZE ZDRAVOTNÍHO POJIŠTĚNÍ"}>
              </Title>

              <Title className="title-box ff--1 fs--18" content={"platný od 1.2.2021"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"2esubafvvyb"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <PriceWrap className="--center el--1 flex--center" style={{"backgroundColor":"rgba(180,237,190,1)"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box text-box--center fs--18 swpf--uppercase" style={{"maxWidth":1000,"paddingRight":0}} content={"<span style=\"color: var(--black);\">Vyšetření k posouzení zdravotní způsobilosti</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black); font-weight: normal;\">Pro potravinářský průkaz\n</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">300 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black); font-weight: normal;\">Pro řidičský průkaz do 65 let</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">600 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black); font-weight: normal;\">Pro zbrojní průkaz\n</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">600 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black); font-weight: normal;\">Řidičů nad 65 let</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">300 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: rgb(0, 0, 0); font-weight: 400;\">Ke studiu</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">200 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: rgb(0, 0, 0); font-weight: 400;\">Pracovně lékařská prohlídka</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">600 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: rgb(0, 0, 0); font-weight: 400;\">Žádost do domova pro seniory&nbsp;</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">200 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: rgb(0, 0, 0); font-weight: 400;\">Výpis lázní bez doporučení</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">200 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="--center el--1 flex--center" style={{"backgroundColor":"rgba(180,237,190,1)"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box text-box--center fs--18 swpf--uppercase" style={{"maxWidth":1000,"paddingRight":0}} content={"<span style=\"color: var(--black);\">vypracování lékařských zpráv</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black); font-weight: normal;\">Výpis z dokumentace&nbsp;</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">300 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"font-weight: normal; color: var(--black);\">Zprávy pro komerční pojišťovny</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">300 - 1000 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--1 flex--center" style={{"backgroundColor":"rgba(180,237,190,1)"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box text-box--center fs--18 swpf--uppercase" style={{"maxWidth":436,"paddingRight":0}} content={"<span style=\"color: var(--black);\">aplikace injekce - očkování</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"font-weight: normal; color: var(--black);\">Případe nehrazené ZP</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">150 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"font-weight: normal; color: var(--black);\">+ kopírování - jedna strana</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">5 Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}